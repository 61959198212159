.personal-data-notice-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personal-data-notice-container.desktop {
  border-radius: 14px;
  background-color: white;
  height: 83px;
}

.personal-data-notice-container.mobile {
  border-radius: 12px;
  background-color: var(--medium-black);
  padding-left: 16px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;

}

.personal-data-notice-container.desktop .shield-icon {
  padding: 20px 23px 20px 20px;
  border-right: solid 1px #F4F4F4;
}
.personal-data-notice-container.desktop .notice-data-text {
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  flex: 1;
}

.personal-data-notice-container.mobile .notice-data-text {
  font-size: 12px;
  color: #F4F4F4;
}

