#navbar-container {
  background-color: var(--primary-blue);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
}
.menu-container {
  margin-left: 50px;
}
.menu-container .nav-menu {
  font-size: 16px;
  font-weight: 700;
  color: white;
  padding: 10px 0;
}

.menu-container .nav-menu.active {
  border-bottom: 3px solid white;
}

#navbar-leading {
  display: flex;
  white-space: nowrap;
}

#navbar-trailing {
  margin-left: 20px;
}

/* style for notification */
#current-user-container:not(.has-avatar) .ant-avatar > img,
#wallet-container .ant-avatar > img,
#notification-container .ant-avatar > img {
  object-fit: none;
}
#current-user-container {
  padding: 8px;
  width: 200px;
}
#current-user-container.ant-dropdown-open {
  background-color: #ffffff1a;
  border-radius: 12px;
}

.user-icon,
.wallet-icon {
  background-color: white !important;
}
.noti-icon {
  background-color: #ffffff33 !important;
}

#notification-container .ant-badge-multiple-words {
  padding: 0 4px;
}
#notification-container .ant-badge-count {
  /* width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;  */
  box-shadow: 0 0 0 2px var(--primary-blue);
}
#notification-container .ant-badge-count,
#notification-container .ant-badge-count * {
  font-weight: 700;
}

#amount-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: white;
}
#amount-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: white;
}

.username-title {
  color: white;
  white-space: nowrap;
  font-weight: 700;
  line-height: 17.5px;
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-role-title {
  color: #ffffffcc;
  font-weight: 12px;
  line-height: 15px;
  font-weight: 700;
}

.user-profile-dropdown .ant-dropdown-menu {
  border-radius: 12px;
  padding: 15px 0;
}
.user-profile-dropdown .ant-dropdown-menu-item {
  padding: 8px 14px;
}

.user-profile-dropdown .ant-dropdown-menu .dropdown-menu-title {
  color: var(--font-darkgray);
}

.user-profile-dropdown #logout-menu {
  color: #f46436;
}

@media only screen and (max-width: 991px) {
  #navbar-container {
    flex-direction: column;
    height: 120px;
    padding: 0 15px;
    justify-content: space-around;
  }
  .menu-container {
    margin-left: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 450px;
    min-width: 360px;
  }
  .menu-container .nav-menu {
    font-size: 14px;
    padding: 5px 0;
  }

  #nav-menu-mobile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: auto;
  }
  #nav-menu-mobile-container #nav-menu-mobile-container-scroller {
    overflow-x: auto;
  }

  #nav-menu-mobile-container #nav-menu-mobile-container-scroller {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  #nav-menu-mobile-container
    #nav-menu-mobile-container-scroller::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .nav-drawer .ant-drawer-content-wrapper {
    height: fit-content !important;
    position: relative;
  }

  .nav-drawer .ant-drawer-body {
    background-color: var(--primary-blue);
  }

  .nav-drawer button {
    background: #ececec1a;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    height: 42px;
  }

  .nav-drawer .ant-drawer-body {
    padding: 25px 14px 20px 14px;
  }
  .nav-drawer-button {
    display: flex;
    justify-content: space-between;
    height: 54px;
    background-color: #ffffff33;
    border-radius: 8px;
    align-items: center;
    padding: 18px;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }
  #close-nav-icon {
    position: absolute;
    right: 0;
  }
  #nav-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #nav-header #nav-drawer-header-title {
    font-size: 16px;
    font-weight: 800;
    color: white;
  }
}

.common-navbar-container {
  display: flex;
  background-color: white;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 55px;
  justify-content: center;
  box-shadow: 0px 2px 11px 0px rgba(0, 31, 112, 0.08);
  -webkit-box-shadow: 0px 2px 11px 0px rgba(0, 31, 112, 0.08);
  -moz-box-shadow: 0px 2px 11px 0px rgba(0, 31, 112, 0.08);
}

.common-navbar-container .common-navbar-title {
  font-size: 16px;
  font-weight: 700;
}

.common-navbar-container #back-button {
  position: absolute;
  left: 15px;
  cursor: pointer;
}

.notification-overlay .ant-popover-inner {
  border-radius: 16px;
  overflow: hidden;
  width: 450px;
  max-width: calc(100vw - 20px);
}

.notification-overlay .ant-popover-inner-content {
  padding: 0;
  width: 100%;
}

.notification-overlay .ant-popover-arrow-content:before {
  background-color: var(--primary-blue);
}

.notification-overlay #notification-header {
  background: var(--primary-blue);
  padding: 22px;
  color: white;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}
.notification-overlay #notification-header .notification-tool {
  color: white;
  font-size: 18px;
}

.notification-overlay #empty-noti-container {
  padding: 22px;
}

.notification-item-container {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.more-noti-button:hover,
.notification-item-container:hover {
  background-color: var(--hover-color);
}

.flag-read {
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background-color: var(--primary-blue);
}

.flag-read-temp {
  width: 10px;
  height: 10px;
}

.more-noti-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
}

#notification-body {
  max-height: 400px;
  overflow-y: auto;
}
