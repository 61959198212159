@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  white-space: pre-line;
}

* {
  font-family: Mulish, Kanit;
}

body {
  overflow: overlay;
}

/* Customized scrollbar */
*::-webkit-scrollbar {
  background: transparent !important;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.9);
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
