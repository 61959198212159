div.wht-popover-content-container div.ant-popover-content {
  background: #5b5b6b;
  box-shadow: 1px 3px 11px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

div.wht-popover-content-container div.ant-popover-inner[role="tooltip"] {
  background: #5b5b6b;
  box-shadow: 1px 3px 11px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

div.wht-popover-content-container span.ant-popover-arrow-content:before {
  background-color: #5b5b6b;
}

div.wht-description-container {
  white-space: pre-line;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
