div.cash-out-tips-container {
  background: #2f334c;
  border-radius: 16px;
  padding: 12px;
  margin: 18px 0px 70px 0px;
}

div.cash-out-tips-container div.tips-image-container {
  padding: 25px 12px;
  background: #424865;
  border-radius: 10px;
  margin-right: 16px;
}

div.cash-out-tips-container div.tips-image-container > img {
  width: 34px;
  height: auto;
}

div.cash-out-tips-container div.cash-out-label-header {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #f4f4f4;
  margin-bottom: 8px;
}

div.cash-out-tips-container div.cash-out-description {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #cccfde;
}
