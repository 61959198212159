.desktop #your-information-form {
  margin-top: 20px;
}

#your-information-form {
  margin-top: 15px;
}

#your-information-form .ant-form-item {
  margin-bottom: 20px;
}

#your-information-container {
  padding-bottom: 15px;
}

#your-information-form .ant-form-item-control-input-content > input,
#your-information-form .ant-input-affix-wrapper,
#your-information-form .ant-select-selector,
#your-information-form .ant-picker {
  height: 42px;
  border-radius: 8px;
  border: solid 1px#F4F4F4;
}

#your-information-form .ant-select-selection-item {
  display: flex;
  align-items: center;
}
#your-information-form .ant-picker input {
  border-radius: 8px;
}

#your-information-form .ant-input-group input {
  border-radius: 0 8px 8px 0;
}

#your-information-form .ant-input-group-addon {
  border-radius: 8px 0 0 8px;
  background-color: white;
  border: solid 1px#F4F4F4;
  border-right: none;
  color: #939393;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

#your-information-form .ant-input-group > .ant-input-affix-wrapper {
  border-radius: 0 8px 8px 0;
}

#your-information-form .ant-form-item-label label {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  height: 14px;
  text-align: left;
  color: var(--font-darkgray);
}

#your-information-form .ant-form-item-label {
  padding-bottom: 9px;
  line-height: 14px;
}

.mobile #your-information-label {
  color: var(--medium-black);
}

#your-information-label {
  color: var(--title-color);
  text-transform: uppercase;
}

#your-information-form .change-text-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  color: var(--primary-blue);
  cursor: pointer;
}

#save-change-button-container {
  width: 100%;
}

#your-information-form #save-change-button {
  height: 46px;
  width: 100%;
  border-radius: 6px;
  margin-top: 5px;
}

/* Desktop style */

.account-content-container.desktop #your-information-container {
  background-color: white;
  border-radius: 24px;
  padding: 23px 37px 40px 33px;
}

.account-content-container.desktop
  #your-information-container
  #save-change-button {
  font-size: 12px;
  font-weight: 800;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.account-content-container.desktop #save-change-button-container {
  text-align: right;
  margin-top: 30px;
  display: flex;
  justify-content: end;
}

.account-content-container.desktop #save-change-button {
  text-align: right;
  color: var(--primary-blue);
  cursor: pointer;
}

.account-content-container.desktop #save-change-button.disabled {
  color: var(--title-color);
  cursor: default;
}

.account-content-container.mobile #save-change-button {
  background-color: var(--primary-green);
  font-size: 14px;
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.account-content-container.mobile #save-change-button.disabled {
  background-color: var(--font-lightgray);
  color: var(--title-color);
}
