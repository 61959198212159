@media only screen and (max-width: 991px) {
  div#cash-out-container {
    padding: 0px;
  }
}

#cash-out-container {
  padding: 25px 25px 30px 30px;
  background: #ffffff;
  border-radius: 24px;
}

#cash-out-container #cash-out-header {
  margin-bottom: 28px;
}

#cash-out-container #cash-out-label,
#cash-out-container #transfer-detail-label {
  /* font-family: "Proxima Nova"; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #454456;
}

#cash-out-container #transfer-detail-label {
  margin-bottom: 16px;
}

#cash-out-container #close-section-text-button {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b7b9b8;
}

#cash-out-container #close-section-text-button:hover {
  opacity: 0.8;
}

#cash-out-container #close-section-text-button:active {
  opacity: 1;
}

#cash-out-container .transfer-amount-text-input input {
  height: 85px;
  border: 1px solid #d6d6d6;
  border-radius: 14px;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
}

#cash-out-container .transfer-amount-text-input input.ant-input::placeholder {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px !important;
  text-align: center;
  /* Layout Button */
  color: #f4f4f4;
}

#cash-out-container div#transfer-summary-label {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #454456;
}

#cash-out-container #withdrawal-row {
  margin-top: 15px;
}

#cash-out-container div.money-row {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #454456;
  margin-bottom: 9px;
}

#cash-out-container #wht-row {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d6d6d6;
}

#cash-out-container div.wht-popover-trigger-container {
  margin-left: 8px;
  cursor: pointer;
}

#cash-out-container div.wht-popover-trigger-container > img {
  width: 15px;
  height: auto;
  display: flex;
  align-items: center;
}

#cash-out-container div#total-row {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #454456;
  padding-bottom: 17px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 30px;
}

#cash-out-container div#term-and-condition-container {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #939393;
  margin-bottom: 19px;
}

#cash-out-container span.text-label-button {
  color: var(--primary-blue);
  cursor: pointer;
}

#cash-out-container span.text-label-button:hover {
  opacity: 0.8;
}

#cash-out-container span.text-label-button:hover {
  opacity: 1;
}

#cash-out-container div#confirm-withdrawal-button-disabled {
  background: rgba(50, 117, 252, 0.5);
  border-radius: 10px;
  padding: 15px 0px;
  color: white;
  cursor: not-allowed;
}

#cash-out-container div#confirm-withdrawal-button {
  background: #3275fc;
  border-radius: 10px;
  padding: 15px 0px;
  color: white;
}

#cash-out-container div#confirm-withdrawal-button:hover {
  opacity: 0.8;
}

#cash-out-container div#confirm-withdrawal-button:active {
  opacity: 1;
}
