.desktop #my-earning-container {
  background-color: white;
  border-radius: 24px;
  padding: 24px 19px 19px 33px;
  margin-top: 18px;
  margin-bottom: 18px;
}

#my-earning-container {
  width: 100%;
}

#my-earning-container .recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 9px;
}

.desktop #my-earning-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: #939393;
  text-transform: uppercase;
}

#my-earning-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--medium-black);
}

#my-earning-container .recharts-responsive-container {
  margin-top: 20px;
}

#my-earning-container line {
  stroke: #f4f4f4;
}

.custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.custom-tooltip .label-value {
  margin: 0;
  color: var(--primary-blue);
}
.custom-tooltip .label-credit {
  margin: 0;
  color: var(--primary-orange);
}
