@media only screen and (min-width: 992px) {
  div#cash-detail-box-container {
    padding: 29px 24px 23px 33px;
    background: #ffffff;
    box-shadow: 6px 15px 48px rgba(0, 31, 112, 0.04);
    border-radius: 24px;
    margin-top: 17px;
  }

  div#cash-detail-label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #939393;
  }

  div#payment-account-status-container,
  div#balance-history-footer-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div#link-bank-account-btn {
    cursor: pointer;
    background: rgba(236, 236, 236, 0.2);
    /* Layout Button */
    border: 1.5px solid #f4f4f4;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #939393;
    padding: 12px;
  }

  div#payment-account-status-container {
    margin-top: 12px;
  }

  div#link-bank-account-btn:hover {
    opacity: 0.8;
  }

  div#link-bank-account-btn:active {
    opacity: 1;
  }

  div.cash-detail-subtopic-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #454456;
  }

  div.balance-section {
    display: flex;
    align-items: center;
  }

  div#balance-amount {
    margin-left: 15px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #22a85b;
  }

  div#cash-history-text-button {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #b7b9b8;
  }

  div#cash-out-button {
    background: var(--primary-green);
    border-radius: 8px;

    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    /* or 14px */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;
    padding: 14px 18px;
    margin-left: 13px;
  }

  div#cash-out-button:hover {
    background: var(--hover-primary-green);
  }

  div#cash-out-button:active {
    background: var(--primary-green);
  }

  div#balance-history-footer-content-container {
    margin-top: 14px;
  }

  div#pending-review-label {
    padding: 12px;
    background: rgba(34, 168, 91, 0.2);
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: var(--primary-green);
    min-width: 155px;
  }

  div#verified-text-icon-container > .label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: var(--primary-green);
    margin-left: 4px;
  }

  div#verified-text-icon-container > .label:hover {
    color: var(--hover-primary-green);
  }
}

@media only screen and (max-width: 991px) {
  div.cash-detail-wrapper-container {
    width: 100%;
    height: 100%;
  }

  /* MOBILE */
  /* TABLET */
  div#cash-detail-box-container {
    width: 100%;
    height: 100%;
  }

  div#cash-detail-label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #5b5b6b;
    margin-bottom: 24px;
  }

  div#payment-account-status-container {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #454456;
  }

  div#verified-text-icon-container {
    cursor: pointer;
  }

  div#verified-text-icon-container > img {
    margin-right: 8px;
  }

  div#verified-text-icon-container > div.label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #22a85b;
  }

  div#pending-review-label {
    margin-top: 10px;
    text-align: center;
    background: rgba(34, 168, 91, 0.2);
    border-radius: 8px;
    padding: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #22a85b;
  }

  div#link-bank-account-btn {
    background: rgba(236, 236, 236, 0.2);
    border: 1.5px solid #f4f4f4;
    border-radius: 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #939393;
    padding: 12px;
    margin-top: 10px;
  }

  div.balance-section {
    margin-top: 23px;
    margin-bottom: 10px;
  }

  div.cash-detail-subtopic-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #454456;
  }

  div#balance-amount {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: right;
    color: var(--primary-green);
  }

  div#cash-out-button {
    background: var(--primary-green);
    border-radius: 8px;
    padding: 14px 0px;

    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 19px;
  }

  div#cash-out-button:hover {
    opacity: 0.8;
  }

  div#cash-out-button:active {
    opacity: 1;
  }

  div#cash-history-text-button {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #b7b9b8;
  }

  div#cash-history-text-button:hover {
    opacity: 0.8;
  }

  div#cash-history-text-button:active {
    opacity: 1;
  }
}


 .cash-out-button-disabled {
  background: var(--primary-green-disabled) !important;
  cursor: not-allowed;
}