@media only screen and (min-width: 992px) {
  div#cash-out-history-container {
    padding: 25px 25px 25px 30px;
    background: #ffffff;
    border-radius: 24px;
  }
}

@media only screen and (max-width: 991px) {
  div#cash-out-history-container {
    padding: 0px;
    width: 100%;
    overflow-y: auto;
  }
}

div#cash-out-history-container div#cash-out-history-label {
  /* font-family: "Proxima Nova"; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #454456;
}

div#cash-out-history-container div#close-section-text-button {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b7b9b8;
}

div#cash-out-history-container div#close-section-text-button:hover {
  opacity: 0.8;
}

div#cash-out-history-container div#close-section-text-button:active {
  opacity: 1;
}

div#cash-out-history-container div#cash-out-history-header {
  padding-bottom: 27px;
}

div#cash-out-history-container div.transaction-element-container {
  padding-bottom: 9px;
  margin-bottom: 19px;
  border-bottom: 1px solid #f4f4f4;
}

div#cash-out-history-container div.transaction-time-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #b7b9b8;
  padding-bottom: 7px;
}

div#cash-out-history-container div#withdrawal-row .label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b6b;
  margin-bottom: 6px;
}

div#cash-out-history-container div#withdrawal-row .value {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: right;
  color: var(--primary-green);
  margin-bottom: 6px;
}

div#cash-out-history-container div#transaction-fee-row div.label,
div#cash-out-history-container div#transaction-fee-row div.value,
div#cash-out-history-container div#wht-row div.label,
div#cash-out-history-container div#wht-row div.value {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #939393;
  margin-bottom: 6px;
}

div#cash-out-history-container div.control-button {
  cursor: pointer;

  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #5b5b6b;
}

div#cash-out-history-container div.control-button-disabled {
  cursor: not-allowed;

  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #d7d7d7;
}

div#cash-out-history-container div.icon-container > img {
  width: 18px;
  height: auto;
  display: flex;
  align-items: center;
}

div#cash-out-history-container
  div.control-button-disabled
  div.icon-container
  > img {
  filter: brightness(0%) saturate(100%) invert(100%) sepia(0%) saturate(7374%)
    hue-rotate(187deg) brightness(75%) contrast(138%);
}

#cash-out-history-container div.transaction-status-badge {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  border-radius: 16px;
  padding: 5px 19px;
  margin: 4px 0px;
  width: fit-content;
}

#cash-out-history-container div.transaction-status-badge.disapprove {
  background-color: #f4643633;
  color: #f46436;
}

#cash-out-history-container div.transaction-status-badge.approved {
  background-color: rgba(34, 168, 91, 0.2);
  color: #22a85b;
}

#cash-out-history-container div.transaction-status-badge.pending {
  background-color: hsla(0, 0%, 59%, 0.2);
  color: #939393;
}

span#cashout-info-24hr {
  font-size: 13px;
  display: inline-block;
}
@media only screen and (min-width: 992px) {
  span#cashout-info-24hr {
    width: 261px;
  }
}
