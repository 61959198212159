div.instruction-detail-container {
  padding-left: 10px;
}

div.instruction-detail-container div.instruction-icon-container > img {
  width: 75px;
  height: auto;
  margin-right: 45px;
}

div.instruction-detail-container div.instruction-rule-icon-container > img {
  width: 18px;
  height: auto;
  margin-right: 12px;
  display: flex;
  justify-content: flex-start;
}

div.instruction-detail-container div.instruction-detail-label {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #939393;
}

div.instruction-detail-container div.instruction-row {
  margin-bottom: 12px;
}

div.instruction-detail-container
  div.multiple-description-wrapper
  > img:nth-child(1) {
  margin-bottom: 23px;
  width: 85px;
  height: auto;
}

div.instruction-detail-container
  div.multiple-description-wrapper
  > img:nth-child(2) {
  width: 81px;
  height: auto;
}

div.instruction-detail-container div.id-card-description-detail-container {
  margin-bottom: 33px;
}

div.instruction-detail-container div#submit-link-bank-form-button {
  text-align: center;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #ffffff;
  background: var(--primary-blue);
  border-radius: 8px;
  padding: 14px 0px;
}

div.instruction-detail-container div#submit-link-bank-form-button:hover {
  opacity: 0.8;
}

div.instruction-detail-container div#submit-link-bank-form-button:active {
  opacity: 1;
}
