:root {
  --primary-color: white;
  --background-color: #e5e5e5;
  --primary-blue: #3275fc;
  --medium-black: #5b5b6b;
  --primary-green: #22a85b;
  --primary-orange: #fdc56e;
  --hover-primary-green: #22c468;
  --font-darkgray: #454456;
  --font-lightgray: #b7b9b8;
  --hover-color: #f5f8ff;
  --title-color: #939393;
  --primary-green-disabled: rgba(34,168,91,0.5);
}
/* var(--primary-orange) */
