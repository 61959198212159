@media only screen and (min-width: 992px) {
  div#link-bank-account-container {
    background: #ffffff;
    border-radius: 24px;
    padding: 25px 25px 35px 30px;
  }

  div#bank-detail-label {
    margin-top: 22px;
  }
}

@media only screen and (max-width: 991px) {
  /* MOBILE */
  /* TABLET */
  div#link-bank-account-container {
    width: 100%;
    overflow-y: auto;
  }

  div#bank-detail-label {
    margin-top: 0px;
  }

  div#link-bank-account-container div#submit-link-bank-form-button,
  div#link-bank-account-container div#submit-link-bank-form-button-disabled {
    margin-bottom: 65px;
  }
}

div#link-bank-account-container div#verify-account-label {
  /* font-family: "Proxima Nova"; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #454456;
}

div#link-bank-account-container div#close-section-text-button {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b7b9b8;
  cursor: pointer;
}

div#link-bank-account-container div#close-section-text-button:hover {
  opacity: 0.8;
}

div#link-bank-account-container div#close-section-text-button:active {
  opacity: 1;
}

div#link-bank-account-container div#bank-detail-label {
  /* font-family: "Proxima Nova"; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #454456;
}

div#link-bank-account-container div.link-account-topic-label {
  margin-top: 12px;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: #454456;
  margin-bottom: 9px;
}

div#link-bank-account-container .verify-account-input-wrapper input {
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  height: 42px;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #5b5b6b;
  padding: 12px 14px;
}

div#link-bank-account-container
  .verify-account-input-wrapper
  input::placeholder {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #b7b9b8;
}

div#link-bank-account-container div.verify-account-select-wrapper {
  position: relative;
}

div#link-bank-account-container
  div.verify-account-select-wrapper
  div.ant-select {
  width: 100%;
  height: 42px;
}

div#link-bank-account-container
  div.verify-account-select-wrapper
  div.ant-select-selector {
  height: 42px !important;
  /* Layout Button */

  border: 1px solid #f4f4f4 !important;
  border-radius: 8px !important;
}

div#link-bank-account-container
  div.verify-account-select-wrapper
  input[type="search"] {
  height: 42px !important;
}

div#link-bank-account-container
  div.verify-account-select-wrapper
  span.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}

div#link-bank-account-container div#upload-book-bank-label,
div#link-bank-account-container div#upload-id-passport-label {
  margin: 40px 0px 12px 0px;
  /* font-family: "Proxima Nova"; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #454456;
}

div#link-bank-account-container div#instruction-book-bank-label,
div#link-bank-account-container div#instruction-id-card-label {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #454456;
  margin-bottom: 20px;
}

div#link-bank-account-container div.book-bank-description-detail-container,
div#link-bank-account-container div.id-card-description-detail-container {
  margin-bottom: 60px;
}

div#link-bank-account-container div#submit-link-bank-form-button {
  background: var(--primary-blue);
  border-radius: 8px;
  padding: 14px 0px;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

div#link-bank-account-container div#submit-link-bank-form-button:hover {
  opacity: 0.8;
}

div#link-bank-account-container div#submit-link-bank-form-button:active {
  opacity: 1;
}

div#link-bank-account-container div#submit-link-bank-form-button-disabled {
  background: rgba(50, 117, 252, 0.5);
  border-radius: 8px;
  padding: 14px 0px;
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: not-allowed;
}
