.mobile #your-setting-label {
  display: none;
}

.mobile #your-setting-label {
  text-transform: capitalize;
}

.desktop #your-setting-label {
  text-transform: uppercase;
}

#your-setting-label {
  color: var(--title-color);
}

.account-content-container.desktop #change-language-wrapper {
  border-radius: 24px;
  margin-top: 18px;
  padding: 26px 35px 37px 33px;
}

#change-language-wrapper {
  background-color: white;
  width: 100%;
}

#change-language-container {
  width: 100%;
  border: solid 1px #f4f4f4;
  background-color: white;
}

.desktop #display-language-label {
  margin-top: 17px;
}

.desktop #display-language-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  color: var(--font-darkgray);
}

.mobile #display-language-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: var(--medium-black);
}

#selected-language-container {
  height: 42px;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 15px;
}

.change-language-item {
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.change-language-item .lang-label {
  margin-left: 2px;
  white-space: nowrap;
}

.language-options-list .ant-dropdown-menu {
  border-radius: 12px;
}

#selected-language-container .change-language-item {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--medium-black);
}
