div.drag-zone-container {
  padding: 40px 0px;
  background: rgba(50, 117, 252, 0.1);
  border: 1.5px dashed rgba(50, 117, 252, 0.3);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

div.upload-button-container {
  padding: 10px 8px 6px 8px;
  background: rgba(50, 117, 252, 0.3);
  border-radius: 10px;
  margin-bottom: 21px;
  display: flex;
  width: fit-content;
}

div.upload-button-container > img {
  width: 24px;
  height: 24px;
}

div.upload-label {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: var(--primary-blue);
}

div.upload-file-detail-container {
  background: rgba(255, 255, 255, 0.1);
  border: 1.5px dashed rgba(50, 117, 252, 0.3);
  border-radius: 8px;
  padding: 34px 0px 19px 0px;
  margin-bottom: 20px;
}

div.file-detail-container {
  padding: 13px;
  background: #f2f2f2;
  border-radius: 100%;
  margin-bottom: 13px;
}

div.file-name-label {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  text-decoration-line: underline;
  color: #5b5b6b;
  margin-bottom: 17px;
}

span.click-text-button {
  color: var(--primary-blue);
  margin: 0px 4px;
}

div#reupload-label {
  /* font-family: 'Mulish'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #939393;
}
