#change-password-container {
  width: 100%;
}

#change-password-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

#change-password-description {
  margin: 15px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #939393;
}

#forgot-password-form .ant-form-item-control-input-content > * {
  border: solid 1px #cbced5;
  border-radius: 8px;
  height: 48px;
}

#forgot-password-form .ant-form-item {
  margin-bottom: 10px;
}

#change-password-button-container {
  margin-top: 10px;
}

#change-password-button-container button {
  font-weight: 700;
  height: 48px;
  border-radius: 8px;
}

#change-password-button-container #cancel-button {
  background-color: #f4f4f4;
  color: var(--font-lightgray);
  border: none;
}

#change-password-button-container #forgot-password-button {
  color: var(--primary-blue) !important;
}

#change-password-container .ant-input-password-icon {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: var(--primary-blue);
}
