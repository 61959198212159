#uid-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}
#icon-grey-scale {
  height: 40px;
  width: 40px;
}

#user-detail-avatar-wrapper {
  position: relative;
  border-radius: 100%;
  border: 1px solid #f4f4f499;
  width: fit-content;
}

#user-detail-avatar-container {
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

.user-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#copy-uid-container {
  overflow: hidden;
}
.user-detail-container.mobile {
  margin-bottom: 40px;
}
.user-detail-container.mobile #copy-uid-container {
  display: flex;
  background-color: #f4f4f4;
  height: 42px;
  align-items: center;
  padding: 0 15px;
  border-radius: 8px;
  color: var(--title-color);
  font-size: 12px;
  width: 100%;
  justify-content: space-between;
  margin-top: 18px;
}

.user-detail-container.mobile #copy-uid-container #id-label {
  border-right: 1px solid white;
  padding-right: 10px;
  margin-right: 10px;
  vertical-align: middle;
  height: 100%;
  display: flex;
  align-items: center;
}

.user-detail-container.mobile #user-detail-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  margin-top: 8px;
}

.user-detail-container.mobile #user-detail-role {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--title-color);
}

#copy-icon {
  -webkit-mask-image: url(/public/images/common/copy.svg);
  mask-image: url(/public/images/common/copy.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  cursor: pointer;
}

.user-detail-container.mobile #copy-uid-container #copy-icon {
  width: 16px;
  height: 16px;
  background-color: var(--medium-black);
  margin-left: 10px;
}

.user-detail-container.desktop {
  width: 264px;
  height: 257px;
  background: linear-gradient(180deg, #454456 -2.33%, #383844 100%);
  box-shadow: 6px 15px 48px rgba(0, 31, 112, 0.1);
  border-radius: 24px;
  padding: 26px 20px 30px 20px;
}

.user-detail-container.desktop #copy-uid-container {
  display: flex;
  background-color: #31303f;
  height: 42px;
  align-items: center;
  padding: 0 15px;
  border-radius: 8px;
  font-size: 12px;
  width: 100%;
  justify-content: space-between;
  color: #f4f4f4;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 23px;
}

.user-detail-container.desktop #user-detail-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
  color: white;
  margin-top: 12px;
  margin-bottom: 5px;
}

.user-detail-container.desktop #user-detail-role {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: #ffffff99;
}

.user-detail-container.desktop #copy-uid-container #copy-icon {
  width: 16px;
  height: 16px;
  background-color: #f4f4f4;
  margin-left: 10px;
}

.upload-avatar-button {
  opacity: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  top: 3px;
  left: 3px;
  background-color: rgba(0, 0, 0, 0.356);
  cursor: pointer;
  width: 65px;
  height: 65px;
  border-radius: 100%;
}
.upload-avatar-button:hover {
  opacity: 100%;
}

.upload-avatar-button .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  user-select: none;
  height: 100%;
  width: 100%;
}
