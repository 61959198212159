#content-container.account-screen {
  background-color: #f7f8fa;
}

#account-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-content-container.desktop #informata-text-container {
  text-align: left;
  margin-top: 55px;
}

.account-content-container.mobile #informata-text-container,
.account-content-container.mobile #informata-text-container * {
  text-align: center;
  max-width: 270px;
}

.account-content-container.mobile {
  background-color: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.account-content-container.mobile .account-content-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.account-content-body {
  padding: 20px 14px 0 14px;
}

.account-text-header {
  font-weight: 14px;
  font-weight: 700;
}

.account-menu-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  background-color: #e7e7e733;
  height: 55px;
  margin-bottom: 10px;
  cursor: pointer;
}

.account-menu-arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#informata-text-title {
  color: var(--title-color);
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#informata-description {
  color: var(--title-color);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

#account-home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 45px;
}

.account-content-container.desktop {
  display: flex;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

/* desktop layout */
/* !Layout pane config  */
.account-pane.left-pane {
  width: 265px;
}

.account-pane.center-pane {
  min-width: 500px;
  margin-left: 60px;
  margin-right: 60px;
  max-width: 510px;
}

.account-pane.right-pane {
  min-width: 425px;
  max-width: 525px;
  background-color: transparent;
}

#user-detail-wrapper {
  background-color: white;
  border-radius: 24px;
}

#user-detail-action-container {
  padding: 28px 20px 20px 20px;
}

#user-detail-action-container #user-mail-phone-container div {
  color: var(--primary-blue);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
}

#user-detail-action-container #user-mail-phone-container div:first-child {
  margin-bottom: 5px;
}

#user-detail-action-container #user-mail-phone-container {
  margin-top: 12px;
  margin-bottom: 28px;
}

.account-content-container.desktop #informata-text-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.account-content-container.desktop #informata-description {
  margin-top: 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

#user-detail-action-button-container button {
  height: 42px;
  border-radius: 8px;
  background-color: #ececec33;
}

#user-detail-action-button-container #change-password-button {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: var(--primary-blue);
  border: solid 1.5px var(--primary-blue);
}

#user-detail-action-button-container #logout-button {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #939393;
  border: solid 1px #f4f4f4;
}

#account-personal-data-container {
  max-width: 670px;
  width: 100%;
}

.change-password-modal-container .ant-modal-content {
  border-radius: 24px;
  height: auto;
}
.change-password-modal-container .ant-modal-content .ant-modal-body {
  padding: 35px 45px;
  height: 100%;
}

#change-password-content-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.change-password-modal-container .ant-modal-close-x {
  display: none;
}

.change-password-modal-container button {
  width: 100%;
  height: 48px;
  border-radius: 10px;
}

.change-password-modal-container .ant-form-item {
  margin-bottom: 12px;
}

.change-password-modal-container .change-password-icon {
  background-color: #c3d4f4;
}

.change-password-modal-container .change-password-icon img {
  object-fit: none !important;
}

#change-password-success-icon {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  text-align: center;
  background-color: #c3d4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-modal-container .change-password-icon img {
  display: flex;
  object-fit: none;
}

.change-password-modal-container .ant-input-password {
  height: 48px;
  border-radius: 10px;
}
.change-password-modal-container .ant-input-suffix {
  /* display: none; */
}

.change-password-modal-container
  .ant-input-password:not(.ant-input-affix-wrapper-status-error) {
  border-color: #cbced5 !important;
}

.change-password-modal-container input::placeholder {
  font-weight: 600;
}

.change-password-modal-container button.ant-btn-primary {
  font-weight: 700;
}

.change-password-modal-container #change-password-button-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.change-password-modal-container
  #change-password-button-container
  #cancel-button {
  box-shadow: none;
  border: 1px solid #e8e8e8;
  color: #e8e8e8;
  background-color: transparent;
}

.change-password-success-icon-badge #check-icon {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-modal-container .ant-spin-nested-loading,
.change-password-modal-container .ant-spin-container {
  height: 100%;
}
